import React from 'react'

const vanillajs = () => {
  return (
    <>
      <div>vanillajs</div>
    </>
  )
}

export default vanillajs