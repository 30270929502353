import React from 'react'

const ReactJS = () => {
  return (
    <>
      <div>ReactJS</div>
    </>
  )
}

export default ReactJS
